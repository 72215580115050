<template>
<div class="page" v-if="load">
    <div class="d-flex justify-content-center">
      <div class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div class="page" v-if="!load">
    <reservation-header
      :status="resData.id ? 'Saved' : 'New'"
      :title="'Zapisnik'"
      @save="saveData"
      @delete="deleteData"
      @saveAndLock="saveAndLock"
      @newNormal="createNormal"
      @newAdvance="createAdvance"
    ></reservation-header>
    <data-form2
      :key="resData['id'] + nextKey"
      :data="resData"
      :formFields="formFields"
      @changed="inputChanged"
      :readonly="true"
      minheight="1px"
      :nooverflow="true"
    />
  </div>
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import SellingsDataService from "../../services/sellings.service";
import SellingFormsDataService from "../../services/selling-form.service";
import ReservationHeader from "../reservation/header/ReservationHeader";
import utils from "../../utils/common";

export default {
  name: "session-form-form",
  props: [],
  components: {
    DataForm2,
    ReservationHeader,
  },
  data() {
    return {
      message: "",
      selling: null,
      nextKey: 0,
      load: false,
    };
  },
  computed: {
    formFields() {
      return {
        rows: [
          [{ id: { type: "hidden" } }],
          [{ h5: { type: "h5", label: "Dokumentacija vozila" } }],
          [
            {
              doc_trafic_licence_handed_over: {
                label: "Saobraćajna dozvola predata kupcu",
                type: "checkbox",
              },
            },
            {
              doc_service_booklet_handed_over: {
                label: "Servisna knjižica predata uredno popunjena",
                type: "checkbox",
              },
            },
          ],
          this.resData['doc_service_booklet_handed_over'] ? [
            {},{},
            {
              doc_service_booklet_handed_over_type: {
                label: "Tip knjizice",
                type: "dropdown",
                dropdownHeight: "150px",
                list: [
                  { id: "d", name: "Digitalna" },
                  { id: "k", name: "Knjižica" },
                ],
              },
            },{}
          ] : [],
          [
            {
              doc_insurance_handed_over: {
                label: "Polisa osiguranja Auto Odgovornosti predata kupcu",
                type: "checkbox",
              },
            },
            {
              doc_spare_key_handed_over: {
                label: "Rezervni ključ predata kupcu",
                type: "checkbox",
              },
            },
          ],
          [{ divider: { type: "divider" } }],
          // Klima uređaj
          // Instrument tabla
          // Poklopac motora, bočna i vrata gepeka
          [
            {
              h5: {
                type: "h5",
                label: "Ispravnost i funkcionalnost unutrašnjosti i komandi",
              },
            },
          ],
          [
            {
              engine_start: {
                label: "Kontakt i start motora",
                type: "checkbox",
              },
            },
            {
              in_line_performace: {
                label: "Ubrzanje vozila u skladu sa performansama vozila",
                type: "checkbox",
              },
            },
          ],
          [
            {
              gearbox_ok: {
                label: "Prenosni mehanizam - menjač",
                type: "checkbox",
              },
            },
            { brake_ok: { label: "Kontrola kočnice", type: "checkbox" } },
          ],
          [
            {
              parking_brake_ok: {
                label: "Provera ručne kočnice",
                type: "checkbox",
              },
            },
            {
              steering_ok: { label: "Upravljački mehanizam", type: "checkbox" },
            },
          ],
          [
            {
              lighting_ok: {
                label: "Signalizacija i osvetljenje na i u vozilu",
                type: "checkbox",
              },
            },
            { siren_ok: { label: "Sirena", type: "checkbox" } },
          ],
          [
            { radio_ok: { label: "Radio", type: "checkbox" } },
            {
              air_conditioning_ok: { label: "Klima uređaj", type: "checkbox" },
            },
          ],
          [
            { heating_ok: { label: "Grejanje", type: "checkbox" } },
            { cabin_fan_ok: { label: "Ventilator kabine", type: "checkbox" } },
          ],
          [
            {
              cabin_light_ok: {
                label: "Svetlo u kabini P+Z",
                type: "checkbox",
              },
            },
            {
              interior_mirror_ok: {
                label: "Unutrašnji retrovizor",
                type: "checkbox",
              },
            },
          ],
          [
            {
              exretnal_mirror_ok: {
                label: "Elektro/manuelno podesivi spoljni retrovizori",
                type: "checkbox",
              },
            },
            { lighter_ok: { label: "Upaljač/pepeljara", type: "checkbox" } },
          ],
          [
            {
              seat_belts_ok: { label: "Sigurnosni pojasevi", type: "checkbox" },
            },
            {
              windows_ok: {
                label: "Elektro ili manuelni podizači stakala",
                type: "checkbox",
              },
            },
          ],
          [
            { rear_shelf: { label: "Zadnja polica", type: "checkbox" } },
            {
              air_bags_ok: {
                label: "Vazdužni jastuci (Air Bag)",
                type: "checkbox",
              },
            },
          ],
          [
            { wipers_ok: { label: "Kontrola rada brisača", type: "checkbox" } },
            { car_board_ok: { label: "Instrument tabla", type: "checkbox" } },
          ],
          [
            {
              front_seats_adjustment_ok: {
                label: "Podešavanje prednjih sedišta",
                type: "checkbox",
              },
            },
            {
              rear_seats_adjustment_ok: {
                label: "Podešavanje zadnjih sedišta",
                type: "checkbox",
              },
            },
          ],
          [
            { front_seats_ok: { label: "Sedišta prednja", type: "checkbox" } },
            { rear_seats_ok: { label: "Sedišta zadnja", type: "checkbox" } },
          ],
          [
            {
              other_doors_ok: {
                label: "Tapaciri pr. i zad. vrata, gepeka, krova vozila",
                type: "checkbox",
              },
            },
            {},
          ],
          [{ divider: { type: "divider" } }],
          [
            {
              h5: {
                type: "h5",
                label: "Ispravnost i funkcionalnost spoljašnjost",
              },
            },
          ],
          [
            {
              hood_ok: {
                label: "Poklopac motora, bočna i vrata gepeka",
                type: "checkbox",
              },
            },
            { alu_wheels_ok: { label: "Alu Felne", type: "checkbox" } },
          ],
          [
            { antenna_ok: { label: "Antena", type: "checkbox" } },
            { wiper_blades_ok: { label: "Metlice brisača", type: "checkbox" } },
          ],
          [{ divider: { type: "divider" } }],
          [
            {
              h5: {
                type: "h5",
                label: "Ispravnost i funkcionalnost ispod haube",
              },
            },
          ],
          [
            { engine_ok: { label: "Motor sa sklopovima", type: "checkbox" } },
            { accumulator_ok: { label: "Akumulator", type: "checkbox" } },
          ],
          [{ divider: { type: "divider" } }],
          [
            {
              h5: {
                type: "h5",
                label: "Pneumatici",
              },
            },
          ],
          [
            { fl_tire_ok: { label: "Prednji levi", type: "text" } },
            { fr_tire_ok: { label: "Prednji desni", type: "text" } },
          ],
          [
            { rl_tire_ok: { label: "Zadnji levi", type: "text" } },
            { rr_tire_ok: { label: "Zadnji desni", type: "text" } },
          ],
          [
            { spare_tire_ok: { label: "Rezervni pneumatik", type: "text" } },
            {},
          ],
          [{ divider: { type: "divider" } }],
          [
            {
              h5: {
                type: "h5",
                label: "Alat",
              },
            },
          ],
          [
            {
              wheel_wrench_ok: { label: "Ključ za točkove", type: "checkbox" },
            },
            { crane_ok: { label: "Dizalica sa ključem", type: "checkbox" } },
          ],
          [
            {
              tire_repair_kit_ok: {
                label: "Set za reparaciju pneumatika",
                type: "checkbox",
              },
            },
            {},
          ],
          [{ divider: { type: "divider" } }],
          [
            {
              h5: {
                type: "h5",
                label: "Čistoća",
              },
            },
          ],
          [
            {
              exterior_clean: { label: "Spoljašnjost čista", type: "checkbox" },
            },
            {
              interior_clean: { label: "Unutrašnjost čista", type: "checkbox" },
            },
          ],
          [{ divider: { type: "divider" } }],
          [
            {
              damages_insurance: {
                label: "Štete prema zapisniku Osiguravajućeg Zavoda",
                type: "textarray",
              },
            },
          ],
          [
            {
              damages: {
                label: "Opis postojećih oštećenja na vozilu",
                type: "textarray",
              },
            },
          ],
          [{ comment: { label: "Napomena", type: "textarray" } }],
          [{ divider: { type: "divider" } }],
        ],
      };
    },
    resData() {
      return this.selling ?? {};
    },
    requiredFields() {
      return [
      ];
    },
  },
  methods: {
    inputChanged(e, name) {
      this.resData[name] = e;
    },
    saveData() {
      // Validate
      if (this.resData['doc_service_booklet_handed_over'] && !this.resData['doc_service_booklet_handed_over_type']) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Morate izabrati tip servisne knjizice!",
        });
        return;
      }
      if (!this.resData['doc_service_booklet_handed_over']) {
        this.resData['doc_service_booklet_handed_over_type'] = null;
      }
      if (this.resData && this.resData.id) {
        this.update(this.resData);
      } else {
        this.create(this.resData);
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    update(newData) {
      SellingFormsDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Podaci su promenjeni!",
          });
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    create(newData) {
      SellingFormsDataService.create(newData)
        .then((newForm) => {
          const temp = { id: this.$route.params.id, sell_form: newForm["data"], form_id: newForm["data"]["id"]};
        SellingsDataService.update(this.$route.params.id,temp)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Zapisnik je kreiran!",
          });
          this.$router.go(-1);
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    deleteData() {
      if (this.resData["invoices"] && this.resData["invoices"].length > 0) {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: "Postoje povezani racuni. Brisanje nije dozvoljeno!",
          });
          return;
      }
      utils.showDialog(
        this.resData,
        "Da li ste sigurni da zelite da obrisete prodaju?",
        "Brisanje prodaje: " + this.resData.id,
        () => {
          SellingFormsDataService.delete(this.resData.id)
            .then((response) => {
              this.$store.commit("app/showAlert", {
                type: "success",
                message: response.data.message,
              });
              this.$store.commit("app/hideModal");

              this.$router.go(-1);
            })
            .catch((e) => {
              console.log(e);
              this.$store.commit("app/showAlert", {
                type: "danger",
                message: e,
              });
            });
        }
      );
    },
  },
  async mounted() {
    this.load = true;
    if (this.$route.params.id != 0) {
      SellingsDataService.get(this.$route.params.id).then(async (res) => {
        this.selling = res.data.sell_form;
        this.load = false;
      });
    } else {
      this.selling = {
      }
      this.load = false;
    }
  },
  created() {},
  watch: {},
};
</script>


<style scoped>
.page {
  max-width: 1200px;
  margin-left: 30px;
}
.company {
  font-size: smaller;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>