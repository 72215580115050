import http from "../utils/http-common";

class SellingFormsDataService {
  getAll() {
    return http.get("/sell-form");
  }

  get(id) {
    return http.get(`/sell-form/${id}`);
  }

  create(data) {
    return http.post("/sell-form", data);
  }

  update(id, data) {
    return http.put(`/sell-form/${id}`, data);
  }

  delete(id) {
    return http.delete(`/sell-form/${id}`);
  }
}

export default new SellingFormsDataService();