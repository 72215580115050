import http from "../utils/http-common";

class SellingsDataService {
  getAll() {
    return http.get("/selling");
  }

  get(id) {
    return http.get(`/selling/${id}`);
  }

  findFromRac(reg) {  
    return http.get(`/selling/rac/${reg}`);
  }

  findFromRacByChassis(reg) { 
    return http.get(`/selling/racCh/${reg}`);
  }

  create(data) {
    return http.post("/selling", data);
  }

  createDocument(data) {
    return http.post("/selling/doc", data);
  }

  update(id, data) {
    return http.put(`/selling/${id}`, data);
  }

  delete(id) {
    return http.delete(`/selling/${id}`);
  }
}

export default new SellingsDataService();